import React, { Component, useEffect, useState } from 'react';
import Link from 'next/link';
import {APIGetPromotionList, APITest, APILogin, APICheckWallet, APIModifyWallet, APIGetProfileUnDeal, APIUserBindWalletNew} from '../../api';
import {Button, Layout, Form, Input, Checkbox, message, Spin} from 'antd';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Head from 'next/head';
import styles from './login.module.scss';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { getQueryString, isTikTok, getCookieByString, getCookie } from '../../tools/action.js';
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import i18n from '@/config/i18n';
import walletConfig from "@/config/walletConfig";
import {useTranslation} from "react-i18next";




const FbLoginIcon = 'https://cdn.igamie.com/static/ic_facebook%403x.png';
const GoogleLoginIcon = 'http://cdn.igamie.com/static/ic%2Bg%403x.png';
const layout = {
    labelCol: {
      span: 0,
    },
    wrapperCol: {
      span: 24,
    },
  };
const tailLayout = {
wrapperCol: {
    offset: 0,
    span: 24,
},
};
const googleAppId =  '470829691764-naqa6nfbnluvn8nf8jrafgich8e9njv1.apps.googleusercontent.com';
const facebookAppId = '2170943866417417';

const login = () => {
    const router = useRouter();
    let { t, i18n} = useTranslation();
    const [language18, setLanguage18] = useState('en')

    const [state, setState] = useState({
        loading: false,
        googleLoading: false,
        fbLoading: false,
        discordLoading: false,
        keepLogin: true,
        isCheck: true
     });

     const [walletState, setWalletState] = useState({
        connector: null,
        fetching: false,
        connected: false,
        chainId: 1,
        showModal: false,
        pendingRequest: false,
        uri: "",
        accounts: [],
        address: "",
        result: null,
        assets: [],
     })

     const [TiktokEnv, setTiktokState] = useState(false)

     const setCookie = (name, value) => {
        let Days = 30;
        let exp = new Date();
        exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
        document.cookie = name + '=' + escape (value) + ';expires=' + exp.toGMTString();
      };

    const getRedirectHref = () => {
        let href = location.href;
        let redirectHref = href.includes('redirect') && href.split('redirect=')[1];
        return redirectHref;
    };

    const onFinish = (values) => {
        let redirectHref = getRedirectHref();
        console.log('redirectHref', redirectHref);
        let channel =  getQueryString('c') || getQueryString('channel') || localStorage.getItem('channel');
        let utmSource = getQueryString('utm_source') || getQueryString('utmSource') || localStorage.getItem('utmSource');
        let referralCode = getQueryString('rc') || getQueryString('referralCode') || localStorage.getItem('referralCode');
        if (!state.isCheck) {
            return message.warning(`
            To proceed, please agree to the Terms of Service and Privacy Policy.
            `)
        }

        setState({
        ...state,
        loading: true
        });
        let params = {
            loginType:0,
            account: values.account,
            email: values.account,
            password: values.password,
        };
        if (referralCode) {
            params.referralCode = referralCode
        }
        if (channel || utmSource){
            params.channel = channel || utmSource;
        }
        if (state.keepLogin){
            params.keepLogin = true;
        }
        localStorage.removeItem('userInfo');
        localStorage.removeItem('token');
        APILogin(JSON.stringify(params)).then(async resp => {
            if (resp.resultCode === 200) {
                localStorage.setItem('token', resp.result.token);
                if (redirectHref && redirectHref !== 'null'){
                  window.location.href = decodeURIComponent(redirectHref);
                } else {
                  window.location.href = '/';
                }
                return
            }
            else if (resp.resultCode === 10002) {
                router.push(`/verification/?account=${values.account}`)
                localStorage.setItem('puid', window.btoa(values.password))
                localStorage.setItem('rc', referralCode)
            }
            else {
                message.error(resp.resultMessage)
            }
            setState({...state, loading: false});
        }, e=> {
            console.log('2222')
            setState({...state, loading: false});
        });

    };
    const onFinishFailed = () => {

    };
    const forgetPwdClick = () => {
        router.push('/signUp?forgetPwd=true');
    };
    const responseGoogle = (resp) => {
        let redirectHref = getRedirectHref();
        let channel =  getQueryString('c') || getQueryString('channel') || localStorage.getItem('channel');
        let utmSource = getQueryString('utm_source') || getQueryString('utmSource') || localStorage.getItem('utmSource');
        let referralCode = getQueryString('rc') || getQueryString('referralCode');
        let tokenId = resp.tokenId;

        if (!state.isCheck) {
            return message.warning(`
            To proceed, please agree to the Terms of Service and Privacy Policy.
            `)
        }

        setState({
        ...state,
        googleLoading: true
        });
        localStorage.removeItem('userInfo');
        let params = {
        loginType: 2,
        loginToken: tokenId,
        channel: channel || utmSource
        };
        if (referralCode) { params.referralCode = referralCode }
        if (state.keepLogin) { params.keepLogin = true; }
        APILogin(JSON.stringify(params)).then(async resp => {
            let isNewRegister = getCookie('isNewRegister')
            localStorage.setItem('isNewRegister', resp.result.isNewRegister);
            if (isNewRegister === '1') {
                localStorage.setItem('token', resp.result.token);
                router.push(`/welcome`)
            } else {
                localStorage.setItem('token', resp.result.token);
                if (redirectHref && redirectHref !== 'null'){
                window.location.href = decodeURIComponent(redirectHref);
                } else {
                window.location.href = '/';
                }
            }
        }, e=> {
           setState({...state, googleLoading:false});
        });
    };
    const responseGoogleError = () => {

    };

    const bindWallet = async (walletAddress, walletType, uuid, particleToken) => {

      const { result } = await APIUserBindWalletNew(JSON.stringify({walletAccounts:walletAddress, walletAddress: walletAddress, walletType: walletType, particleUuid: uuid, particleToken: particleToken}));
      return !!result;

    };
    const responseFacebook = (resp) => {
        let redirectHref = getRedirectHref();
        let channel =  getQueryString('c') || getQueryString('channel') || localStorage.getItem('channel');
        let utmSource = getQueryString('utm_source') || getQueryString('utmSource') || localStorage.getItem('utmSource');
        let referralCode = getQueryString('rc') || getQueryString('referralCode');
        let accessToken = resp.accessToken;

        if (!state.isCheck) {
            return message.warning(`
            To proceed, please agree to the Terms of Service and Privacy Policy.
            `)
        }
        setState({
        ...state,
        fbLoading: true
        });
        localStorage.removeItem('userInfo');
        let params = {
        loginType: 3,
        loginToken: accessToken,
        accessToken: accessToken,
        channel: channel || utmSource
        };
        if (referralCode) { params.referralCode = referralCode }
        if (state.keepLogin) {
           params.keepLogin = true;
        }
        APILogin(JSON.stringify(params)).then(async resp => {
            if (resp.result) {
              let isNewRegister = getCookie('isNewRegister')
              localStorage.setItem('isNewRegister', resp.result.isNewRegister);
              if (isNewRegister === '1') {
                localStorage.setItem('token', resp.result.token);
                router.push(`/welcome`)
              } else {
                localStorage.setItem('token', resp.result.token);
                if (redirectHref && redirectHref !== 'null' ){
                  window.location.href = decodeURIComponent(redirectHref);
                } else {
                  window.location.href = '/';
                }
              }
            } else {
              message.error(resp.resultMessage)
            }
            setState({...state, fbLoading:false});
        }, e=> {
           setState({...state, fbLoading:false});
        });

    };
    const facebookClick = (onclick) => {
       onclick();
    };

    const onRegister = () => {
        console.log('router', router)
        let redirect = getQueryString('redirect');
        if (redirect && redirect !== 'null') {
            localStorage.setItem('redirectHref', redirect)
        }
        console.log('redirect', redirect);
        let channel =  getQueryString('c') || getQueryString('channel') || localStorage.getItem('channel');
        let utmSource = getQueryString('utm_source') || getQueryString('utmSource') || localStorage.getItem('utmSource');
        let referralCode = getQueryString('referralCode') || getQueryString('rc')
        channel = channel || utmSource;

        let queryParams = '?page=l'
        if (channel) {
            queryParams += `&channel=${channel}`
        }
        if (referralCode) {
            queryParams += `&rc=${referralCode}`
        }
        if (redirect) {
            queryParams += `&redirect=${redirect}`
        }
        router.push(`/signUp/${queryParams}`)

        // if (referralCode) {
        //     if (channel){
        //         router.push('/signUp?channel=' + channel + "&redirect=" + redirect + "&rc=" + referralCode );
        //     } else {
        //         router.push('/signUp?rc=' + referralCode );
        //     }
        // } else {
        //     if (channel){
        //         router.push('/signUp?channel=' + channel + "&redirect=" + redirect);
        //     } else {
        //         router.push('/signUp');
        //     }
        // }

    };

    const gotoBack = () => {
        router.back();
    };

    const onSessionUpdate = () => {

    }

    const onDisconnect = () => {

    }

    const getAccountAssets = async () => {
        const { address, chainId } = walletState
    }

    const onConnect = async ( payload, signData, walletNumber ) => {
        const { chainId, accounts } = payload.params[0];
        const address = accounts[0];
        console.log('ADDRESS', address);
        checkWalletAccount(address, signData, walletNumber);
        // walletLogin(accounts.join(','), address)
        // await setWalletState({
        //     ...walletState,
        //     connected: true,
        //     chainId,
        //     accounts,
        //     address,
        // })
    }


    const checkWalletAccount = (walletAddress, signData, walletNumber) =>{
      let referralCode = getQueryString('referralCode') || getQueryString('rc')
      APICheckWallet({
        account:walletAddress
      }).then(resp=>{
        console.log(resp)
        if (resp.result){
          walletLogin(walletAddress, walletAddress, signData, walletNumber);
        } else {
          console.log("跳转去绑定");
        //   router.push("/walletBindEmail/[address]",
        //     `/walletBindEmail/${walletAddress}`, {locale:router.locale});
          localStorage.setItem('waddress', walletAddress)
          localStorage.setItem('walletSignData', signData)
          router.push(`/walletBindEmail/?rc=${referralCode}`)
        }
      });
    };



    const walletLogin = (walletAccounts, accessToken, signData, walletNumber) => {
        let redirectHref = getRedirectHref();
        APILogin(JSON.stringify({
            walletAccounts,
            accessToken,
            isBindAccount: 0,
            loginType: 5,
            loginToken: signData,
            walletNum: walletNumber,
        })).then((resp) => {
            if (resp.result){
              console.log('resp', resp)
              localStorage.setItem('token', resp.result.token);
              let isNewRegister = getCookie('isNewRegister')
              console.log('isNewRegister', isNewRegister)
              localStorage.setItem('isNewRegister', resp.result.isNewRegister);
              if (isNewRegister === '1') {
                  localStorage.setItem('token', resp.result.token);
                  router.push(`/welcome`)
              } else {
                  localStorage.setItem('token', resp.result.token);
                  if (redirectHref && redirectHref !== 'null'){
                  window.location.href = decodeURIComponent(redirectHref);

                  } else {
                  window.location.href = '/';
                  }

              }
            }
        }, err => {
            localStorage.removeItem('walletconnect')
        })
    }



    const subscribeToEvents = async (connector) => {
        // console.log('walletState', walletState)
        // const { connector } = walletState
        console.log('xixixixi', connector)
        if (!connector) {
            return
        }
        connector.on('session_update', async (error, payload) => {
            console.log('connector.on("session_update")')
            if (error) {
                throw error
            }
            const { chainId, accounts } = payload.params[0]
            onSessionUpdate(accounts, chainId)
        })
        connector.on('connect', async (error, payload) => {
            console.log(`connector.on("connect")`);
            if (error) {
                throw error;
            }
            const { chainId, accounts } = payload.params[0];
            const address = accounts[0];
            const walletNumber = Math.floor(Math.random() * 1000)
            const msgParams = [
              walletConfig.WALLET_SIGN_MESSAGE + walletNumber,
              address,
            ];
            const signData = await connector.signPersonalMessage(msgParams);
            onConnect(payload, signData, walletNumber)
        })
        connector.on('disconnect', (error, payload) => {
           if (error) {
               throw error
           }
           onDisconnect('disconnecttttttttt')
        })
        if (connector.connected) {
            const { chainId, accounts } = connector;
            const address = accounts[0];
            console.log('connetceddddddd', connector)
            const walletNumber = Math.floor(Math.random() * 1000)
            const msgParams = [
              walletConfig.WALLET_SIGN_MESSAGE + walletNumber,
              address,
            ];
            const signData = await connector.signPersonalMessage(msgParams);
            checkWalletAccount(address, signData, walletNumber);
            // walletLogin(accounts.join(','), address)
            // setWalletState({
            //     ...walletState,
            //     connected: true,
            //     chainId,
            //     accounts,
            //     address,
            // })
            // onSessionUpdate(accounts, chainId)
        }
        // setWalletState({ connector })
    }

    const gotoWallectConnect = () => {
        router.push(`/walletLogin/`)
    }

    const wallectConnect = async () => {
        if (!state.isCheck) {
            return message.warning(`
            To proceed, please agree to the Terms of Service and Privacy Policy.
            `)
        }
        const bridge = 'https://bridge.walletconnect.org';
        const connector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal,  })
        if (!connector.connected) {
            await connector.createSession()
        }
        await subscribeToEvents(connector)
    }

    const [testUuid, setTestUuid] = useState("");


    const checkLogin = async () => {
      const {result, resultCode} = await APIGetProfileUnDeal();
      return !(resultCode === 10006 || resultCode === 10000 || resultCode === 10005 || resultCode === 10007);

    };

    useEffect(() => {
        setTiktokState(isTikTok());
        if (router.locale === 'ja-JP') {
          i18n.changeLanguage('ja')
        }
        if (getQueryString('redirect') &&  getQueryString('redirect') !== 'null') {
          localStorage.setItem('redirect', getQueryString('redirect'))
          setLanguage18(localStorage.getItem('i18nextLng'))
        }

    }, [])

    useEffect(() => {
     let redirectHref = getQueryString('redirect')
     if (redirectHref) {
      localStorage.setItem('redirect', redirectHref)
     }
    }, [])



    return (
        <>
         <Head>
         <title>imomo - Login or sign up</title>
         </Head>
         <Layout className={`animated fadeIn login-page  ${styles.login_page}`}>
         <div className={styles.login}>
            {/* <div className='back-icon-wrapper'>
            <span className='back-icon' onClick={ gotoBack } >
                <img className='back-icon-img' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAgCAYAAAAffCjxAAAAAXNSR0IArs4c6QAAAc5JREFUSEud1NtuTVEUxvH/eD/UKVEq1AVxalCpOLTVOh9LKOFCJIQ4i9C0VfUA3sU1in4yZM6d0Xbttdbc42Znrb3mL988jGn0WJIMeAB8M7NH/lBcCXkI7AWW/bcYCkn2hQSPi6CE3Af2B+QJsKc1lJB7wIGAPAUGzWy5BHLkYECeAbsc8XetIEnTwKFVyG4z+5vfNUKS7gKHA/I8JekgjYkk3QGGAvIC2BmTNCaSdBs4EpCXwEAV0jWRpFvA0YC8AnZ0QyohSVPAsYC8TsifuhZYsdiSbgLDYcAbYLuZ1SIrEkm6ARwPyFugvw3SgSRdA0YC8g7Y1hb5D0m6CpwIyPuE/C65FhxyxLFcX4F1ZvajCPKPJZ0ELoeBi8D6Eqyza5JOAZcC9gXY0BZbvf2ngYsBW0jYz6ZprmlaSWeAC2HgZ6DPzGqxyu6XNAqcD9g8sLEO63qNSBoDzgVsLmG/qqZZex9JOgtMhIGzwCYzW4O1udgccjBXJdYIpXM2CYwHbAbYEpO1ghLm6+XrlsuxzWa21GnapjOS/5fkO+k7mutTSrbUOlHA/MD6wc31EdhaDKVpeit5S+X60BOUMG9yb3avkZ6hhF0BvpvZ9X/fGbc7gLVB+AAAAABJRU5ErkJggg==' />
            </span>
            </div>  */}
            <div className={styles.login_wrapper}>
                <div className={styles.wrapper_name}>
                    {t('login')}
                    /
                    {t('signUp')}
                </div>
                <div className={styles.wallet_connect_btn} onClick={gotoWallectConnect} >
                    {/* <span className={styles.wallet_connect_icon}></span> */}
                    {/* <span className={styles.btn_name}>{t('continueWalletConnect')}</span> */}
                    <span className={styles.btn_name}>Login with Wallet</span>
                </div>
                <div className={styles.or}>{t('or')}</div>
              {testUuid}
                <div className={styles.account_form}>
                    <div className={styles.login_password_container}>
                        <Form
                            {...layout}
                            name='basic'
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            validateTrigger='onSubmit'
                            onFinishFailed={onFinishFailed}>
                            <Form.Item
                                name='account'
                                className={styles.email_form}
                                rules={[
                                {
                                    required: true,
                                    message: t('AccountMissing'),
                                    // message:'2121'
                                },
                                  ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                      if (
                                        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}(,[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,6})*$/.test(
                                          value
                                        ) || /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(value)
                                      ) {
                                        return Promise.resolve();
                                      }

                                      return Promise.reject(
                                        'Sorry, only letters (a-z) numbers (0-9), and priods(.) are allowed.'
                                        // 'Your password must be between 8-16 charactors long and include at least one uppercase letter(A-z) or one lower caseletter(a-z) or special characters'
                                      );
                                    },
                                  })
                                ]}>
                                <Input
                                autoComplete='false'
                                className={styles.ant_input}
                                placeholder={t('emailPhone')}
                                // placeholder={ language18 === 'ja' ? `メールアドレス / 電話番号` : t('emailPhone') }
                                />
                            </Form.Item>

                            <Form.Item
                                name='password'
                                className={styles.password_form}
                                rules={[
                                {
                                    required: true,
                                    message: t('pleasePassword'),
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                      if (
                                        /^^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9!@#$?.,%^&*_]{8,16}$/.test(
                                          value
                                        )
                                      ) {
                                        return Promise.resolve();
                                      }

                                      return Promise.reject(
                                        t('passwordVerification')
                                        // 'Your password must be between 8-16 charactors long and include at least one uppercase letter(A-z) or one lower caseletter(a-z) or special characters'
                                      );
                                    },
                                }),
                                ]}>
                                <Input.Password
                                autoComplete='false'
                                className={styles.ant_input_password}
                                placeholder={t('passwordPlacehold')}
                                // placeholder={ language18 === 'ja' ? `パスワード` : t('passwordPlacehold')}
                                iconRender = { (visible) => ( visible ? <img style={{ width:'18px' }} src={'http://static-img.igamie.com/static/eyes_can.png'}  />  : <img style={{ width:'18px' }} src='http://static-img.igamie.com/static/eyes_no.png' />   ) }
                                />
                            </Form.Item>


                            <Form.Item {...tailLayout} className={styles.login_btn_form_item}>
                                <div className={styles.continue_wrapper}>
                                    <a className={styles.forget_pwd} onClick={forgetPwdClick} >
                                    {t('forgotPwd')}
                                    </a>
                                    <Button
                                    type='primary'
                                    htmlType='submit'
                                    loading={state.loading}
                                    className={ `${styles.login_click_btn} ${styles.login_click_btn_login}` }>
                                    {t('continue')}
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className={styles.register_container}>
                        <div className={styles.or}>{t('or')}</div>
                        <div className={styles.social_login}>
                        <FacebookLogin
                            appId={facebookAppId}
                            callback={responseFacebook}
                            render={(renderProps) => (
                            <Button
                                loading={state.fbLoading}
                                className={styles.fb_img_wrapper}
                                onClick={ () => {  if (! state.isCheck) { return message.warning(`
                                To proceed, please agree to the Terms of Service and Privacy Policy.
                                `) } facebookClick(renderProps.onClick); }}>
                                    <span></span>
                                    {/* <img src={FbLoginIcon} className='fb-login-icon'  /> */}
                                    {/* <span>{t('loginWithFacebook')}</span> */}
                            </Button>
                            )}
                        />
                        {/* <div onClick={ twitterLogin } className={styles.twitter_login}></div>*/}

                        </div>
                    </div>

                    <div className={`${styles.register_container} ${styles.tc_wrapper}`}>
                        {
                          router.query.forgetPwd ? null :
                          <div className={styles.agree}>
                            <div className={styles.by_sign_up}>
                              <span onClick={() => { setState({ ...state, isCheck: !state.isCheck }); }}  className={ state.isCheck ? 'checkbox-new' : 'checkbox-no-new' } ></span>
                              <span>
                                {t('bySigningUp')}
                                <a className={styles.link_style}  href='https://igamie.s3.ap-southeast-1.amazonaws.com/config/privacy.html' > {t('privacyPolicy')}</a>  &

                                <a className={styles.link_style}  href='https://igamie.s3.ap-southeast-1.amazonaws.com/config/terms.html' > {t('termsConditions')}</a> &

                                <a className={styles.link_style}  href='https://static-img.igamie.com/config/igamie_rewards_terms_of_service.html' > {t('rewardTermCondition')}</a>

                              </span>
                            </div>
                         </div>
                        }
                    </div>


                </div>

            </div>


          </div>

         </Layout>
        </>
    );
};


export default login;
